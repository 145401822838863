import * as React from 'react';
import {Link} from 'gatsby';

import {Image, Ratio, Pagination} from 'react-bootstrap';

import MissingNewsImage from '@components/news/missing-news-image';
import {useIntl} from 'gatsby-plugin-intl';

const NewsGrid = ({news, itemsPerPage}) => {
  const {locale} = useIntl();

  const perPage = itemsPerPage || 24;
  const needsPagination = news?.length > perPage;

  const [currentItems, setCurrentItems] = React.useState(null);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    const endOffset = itemOffset + perPage;
    setCurrentItems(news.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(news.length / perPage));
  }, [itemOffset, perPage, news]);

  const handlePageClick = page => {
    setCurrentPage(page);
    setItemOffset((page * perPage) % news.length);
  };

  const renderBoxes = () => {
    if (!Array.isArray(currentItems) || !currentItems.length > 0) return null;

    return currentItems.map(item => (
      <div className="news-page__grid-container__item" key={item.slug}>
        <div>
          <Link to={`/aktualnosci/${item.slug}`}>
            {item.image && (
              <Ratio aspectRatio="16x9">
                <Image src={item.image} fluid rounded className="image-cover" />
              </Ratio>
            )}
            {!item.image && (
              <Ratio aspectRatio="16x9">
                <MissingNewsImage />
              </Ratio>
            )}
          </Link>
          <span>{item.date}</span>
          <Link to={`/aktualnosci/${item.slug}`}>
            <h5>{item.title[locale] || item.title.pl}</h5>
          </Link>
          <p>{item.excerpt[locale] || item.excerpt.pl}</p>
        </div>
      </div>
    ));
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 0; i < pageCount; i++) {
      pages.push(i);
    }
    return (
      <Pagination size="md">
        {pages.map(item => {
          return (
            <Pagination.Item key={item} active={item === currentPage} onClick={() => handlePageClick(item)}>
              {item + 1}
            </Pagination.Item>
          );
        })}
      </Pagination>
    );
  };

  return (
    <>
      <div className="news-page__grid-container">{renderBoxes()}</div>
      {needsPagination && <div className="news-page__pagination">{renderPagination()}</div>}
    </>
  );
};

export default NewsGrid;
